<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">

    <div class="col-md-6 col-lg-6">
      <div class="card">
        <div class="card-header">
          <h3 >{{ 'usuarios.editarusuario' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-5">
              <kendo-label text="{{ 'usuarios.usuario' | translate}}">
                <kendo-textbox formControlName="nombreUsuario"
                               [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                <div *ngIf="submitted && f.nombreUsuario.errors" class="invalid-feedback">
                  <div *ngIf="f.nombreUsuario.errors.required">
                    {{ 'usuarios.nombreusuariorequerido' | translate}}
                  </div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'usuarios.nombre' | translate}}">
                <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre.errors.required">{{ 'usuarios.nombrerequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'usuarios.apellido1' | translate}}">
                <kendo-textbox formControlName="apellido1"
                               [ngClass]="{ 'is-invalid': submitted && f.apellido1.errors }"></kendo-textbox>
                <div *ngIf="submitted && f.apellido1.errors" class="invalid-feedback">
                  <div *ngIf="f.apellido1.errors.required">{{ 'usuarios.apellidorequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'usuarios.apellido2' | translate}}">
                <kendo-textbox formControlName="apellido2"
                               [ngClass]="{ 'is-invalid': submitted && f.apellido2.errors }"></kendo-textbox>
              </kendo-label>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-7">
              <kendo-label text="{{ 'usuarios.email' | translate}}">
                <kendo-textbox formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">{{ 'usuarios.emailusuariorequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col">
              <kendo-label text="{{ 'usuarios.tipo' | translate}}">
                <kendo-dropdownlist formControlName="usuarios_tiposCombo" [data]="tiposUsuarios_DAT" [textField]="'nombre'"
                                    [valueField]="'id'" (valueChange)="valueChange($event)" [value]="selectedItem" class="form-control">
                </kendo-dropdownlist>
                <div *ngIf="submitted && f.usuarios_tiposCombo.errors" class="invalid-feedback">
                  <div *ngIf="f.usuarios_tiposCombo.errors.required">
                    {{ 'usuarios.tipousuariorequerido' | translate}}
                  </div>
                </div>
              </kendo-label>
            </div>
          </div>
         
          </div>

        </div>
      </div>
    

    <div class="col-md-6 col-lg-6">
      <div class="card">
        <div class="card-header">
          <h3>{{ 'usuarios.contraseña' | translate}}</h3>
          
        </div>

        <div class="card-body">        
          <div class="form-row">
            <div class="form-group col">
              <kendo-label text="{{ 'usuarios.contraseña' | translate}}">
                <input kendoTextBox type="password" formControlName="password" class="form-control"
                       [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">{{ 'usuarios.contraseñarequerida' | translate}}</div>
                  <div *ngIf="f.password.errors.minlength">{{ 'usuarios.contraseña6caracteres' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col">
              <kendo-label text="{{ 'usuarios.confirmarcontraseña' | translate}}">
                <input kendoTextBox type="password" formControlName="confirmPassword" class="form-control"
                       [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">
                    {{ 'usuarios.contraseñaconfirmacionrequerida' | translate}}
                  </div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch">
                    {{ 'usuarios.contraseñasnocoinciden' | translate}}
                  </div>
                </div>
              </kendo-label>
            </div>
        </div>
        <div *ngIf="!isAddMode">
          <kendo-label text="*{{ 'usuarios.dejelovacio' | translate}}">
          </kendo-label>
        </div>
        </div>
       
    </div>
  </div>
</div>

    <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'botones.guardar' | translate}}
    </button>
    <a routerLink="/usuarios" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
</form>
